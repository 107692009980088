@use "../config/" as *;
@forward "./../swiper/swiper-bundle";

.masthead-row {
    & > div {
        @extend %p-x-zero;
    }
}
.event-masthead {
    margin: 0;
    @extend %p-zero;
    .waf-head {
        @extend %d-none;
    }
    .event {
        &-banner {
            @extend %ratio-2-3;
            &::after {
                content: '';
                z-index: var(--zindex-pattern);
                background:url('/static-assets/images/cssimages/event-masthead/w-champ.png?v=#{$image-version}') no-repeat;
                background-position: unset;
                background-size: cover;
                @include position-combo(inset);
            }
            @each $event-name,
            $event-id in $events {
                &.#{$event-name} {
                    &::after {
                        background:url('/static-assets/images/cssimages/event-masthead/#{$event-id}.png?v=#{$image-version}') no-repeat;
                        background-position: unset;
                        background-size: cover;
                    }
                    .event {
                        .text {
                            @extend %event-bg-#{$event-id};
                            @extend %badges;
                            @extend %c-white-10;
                            @extend %d-block;
                        }
                    }
                    .logo-image {
                        background:url(/static-assets/images/cssimages/svg/events/#{$event-name}.svg?v=#{$image-version}) no-repeat center center;
                        background-size: contain;
                    }
                }
            }
            &.olympic-games .logo-image {
                background:url(/static-assets/images/cssimages/svg/events/olympic-qualifying-tournament.svg?#{$image-version}) center / contain no-repeat; 
            }
            &.olympic-qualifying-tournament, &.olympic-games {
                .logo-image {
                    top: -3rem;
                    right: -9rem;
                }
                .meta {
                    color: var(--c-blue-2);
                    &-date, &-country {
                        color: var(--c-blue);
                    } 
                }
                .event .text {
                    color: var(--c-blue-2);
                }
                .title {
                    color: var(--c-blue);
                }
                .btn-fill {
                    background-color: var(--c-blue);
                    .text {
                        color: var(--c-white);
                    }
                    &:hover .text {
                        color: var(--c-orange);
                    }
                }
                .btn-outline {
                    border-color: var(--c-blue);
                    .text {
                        color: var(--c-blue);
                    }
                    &:hover {
                        border-color: var(--c-orange);
                        .text {
                            color: var(--c-orange);
                        }
                    }
                }
            }
        }
        &-banner-wrapper {
            @include flex-config(flex, column-reverse);
            @extend %h-100;
        }
        &-content {
            padding: 0 var(--full-space) var(--two-space);
            .meta {
                display: inline-block;
                @extend %m-x-quarter;
                @extend %font-14-pr;
                @extend %c-white-7;
                &-date,
                &-country {
                    @extend %font-16-pb;
                    @extend %c-white-10;
                }
                &.meta-age {
                    @extend %uppercase;
                }
            }
            .event {
                margin-left: calc(var(--half-space) + var(--quarter-space));
                @extend %m-t-full;
            }
        }
    }
    .logo-image {
        width: 22rem;
        height: 22rem;
        @include position(var(--one-n-half-space), -11rem);
    }
    .title {
        @extend %font-42-sb;
        @extend %c-white-10;
    }
    .venue-info {
        flex-wrap: wrap;
        @extend %flex-n-c;
    }
    .country {
        &-info {
            @extend %flex-n-c;
        }
        &-flag {
            width: 2rem;
            height: 2rem;
            @extend %m-x-half;
        }
        &-list {
            @extend %flex-column;
            &.more-candidates {
                margin-left: 9rem;
                @extend %w-100;
                @extend %relative;
                &::before {
                    content: 'Candidates:';
                    @include position(0,null,null,-9rem);
                    @extend %capitalize;
                    @extend %c-pure-white-10;
                    @extend %font-14-pb;
                }
            }
        }
    }
    .card-action {
        gap: var(--full-space);
        margin-top: 2rem;
        @extend %flex-column;
    }
    .btn-fill {
        @extend %flex-c-c;
        @extend %text-center;
        .text {
            line-height: 1.3;
            @extend %c-blue-10;
            @extend %font-14-pr;
        }
        &:hover {
            @extend %c-blue-bg-10;
            .text {
                @extend %c-orange-10;
            }
        }
    }
    .tournament-info {
        // width: calc(100% - 8rem);
    }
    .title {
        @extend %uppercase;
    }
    .swiper {
        &-button {
            @extend %d-none;
            &::after {
                font-size: 3rem;
                @extend %c-white-10;
            }
            &-prev {
                left: var(--one-n-half-space);
            }
            &-next {
                right: var(--one-n-half-space);
            }
        }
    }
}
.watch-center {
    .event-masthead {
        .card-action {
            @extend %gap-full;
            @extend %flex-column-n-n;
            .btn-outline {
                @extend %text-center;
            }
        }
    }
}
.watch-center {
    .event {
        &-banner {
            height: 100%;
            &-slider {
                height: 45rem;
                &.dependency-show {
                    height: calc(var(--window-inner-height) - var(--header-height) + 25rem);
                    .event-banner-wrapper {
                        margin-top: 26rem;
                        flex-direction: unset;
                    }
                }
            }
        }
    }
    .event-masthead {
        .card-action {
            flex-direction: row;
        }
        .dependacy-layout-wrapper {
            height: 0;
        }
    }
}
@media (min-width:$tablet-min-width) {
    .event-masthead {
        .event {
            &-banner {
                padding-inline: var(--container-white-space);
                height: 45rem;
                flex-direction: row;
                align-items: center;
                &::after {
                    background-position: center;
                    background-size: 100% 100%;
                }
                &.olympic-qualifying-tournament, &.olympic-games {
                    .event-thumbnail {
                        height: 100%;
                    }
                    .logo-image {
                        width: 20rem;
                        height: 100%;
                        background-position: top center;
                        background-size: 100% auto;
                    }
                }
            }
            &-banner-wrapper {
                gap: var(--one-n-half-space);
                @include flex-config(flex, row, space-between, center);
            }
            &-content {
                padding: 0;
                flex-grow: 1;
                .meta {
                    font-size: 1.8rem;
                    margin-bottom: 0;
                    &-date,
                    &-country {
                        font-size: 2.2rem;
                        margin-bottom: 0;
                    }
                }
                .event {
                    margin-top: 0;
                }
            }
        }
        .tournament-info {
            display: flex;
            align-items: baseline;
        }
        .logo-image {
            display: flex;
            @include position(unset, unset, null, null, unset);
        }
        .title {
            margin-block: var(--half-space);
            font: 6.4rem/8.4rem $font-sb;
        }
        .country-flag {
            width: 3rem;
            height: 3rem;
            margin-bottom: 0;
        }
        .country-list {
            &.more-candidates {
                width: auto;
                margin-left: 14.5rem;
                &::before {
                    left: -14rem;
                    font-size: 2.2rem;
                    line-height: 3.6rem;
                }
                .country-flag {
                    margin-bottom: 0;
                }
                .meta-country {
                    margin-bottom: 0;
                }
            }
        }
        .card-action {
            margin: 2.8rem 0 0;
            width: max-content;
            flex-direction: row;
        }
        .swiper-button {
            display: block;
        }
    }
    .watch-center {
        .event {
            &-banner {
                height: 100%;
                &-slider {
                    &.dependency-show {
                        height: calc(var(--window-inner-height) - var(--header-height));
                        .event-banner-wrapper {
                            align-items: flex-start;
                            margin-top: var(--container-white-space);
                        }
                    }
                }
            }
        }
    }
}