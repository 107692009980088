@use "../config/" as *;
//poll
.event-poll {
    padding: 0;
    margin: 0 var(--half-space-negative);
    @extend %dash-pagination;
    &:after {
        content: '';
        background: url(/static-assets/images/events/event-poll-bg.png?v=#{$image-version}) no-repeat center;
        background-size: cover;
        @include position-combo(inset);
    }
    .layout-wrapper {
        max-width: unset;
    }
    .card {
        &-meta {
            flex-wrap: wrap;
            @extend %flex-c-c;
            @extend %text-center;
        }
        &-item {
            min-height: 60rem;
            @extend %w-100;
            @extend %flex-column;
        }
        &-thumbnail {
            z-index: -1;
            @include position-combo(inset);
            top: auto;
            bottom: 24rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: unset;
                object-fit: contain;
                filter: drop-shadow(0 -27rem 4rem hsl(var(--hsl-c-white)/0.6));
            }
            .team {
                width: 50%;
            }
        }
        &-content {
            @extend %flex-column-c-c;
            @extend %c-white-10;
        }
        &-title {
            line-height: 1.2;
            @extend %c-white-10;
            @extend %font-42-sb;
            @extend %uppercase;
            @extend %flex-c-c;
            @extend %m-t-full;
        }
        &-info {
            width: 80%;
            border: .07rem solid hsl(var(--hsl-c-white)/ .7);
            border-radius: .6rem;
            background: hsl(var(--hsl-c-white)/ .2);
            box-shadow: 0 .3rem 1.7rem 0 hsl(var(--hsl-c-black)/ .15);
            backdrop-filter: blur(.3rem);
            flex-wrap: wrap;
            position: absolute;
            bottom: 7.5rem;
            @extend %flex-sb-c;
            @extend %card;
        }
        &-info-title {
            margin: 0 auto var(--one-n-half-space);
            @extend %w-100;
            @extend %font-20-pb;
            @extend %uppercase;
            @extend %text-center;
        }
    }
    .date {
        @extend %w-100;
    }
    .team {
        width: calc(50% - 2rem);
        @extend %flex-column-c-c;
        &-logo {
            width: 7.6rem;
            height: 7.6rem;
            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %c-orange-bg-10;
        }
        &-result {
            height: 7.6rem;
            @extend %font-32-pb;
            @extend %c-orange-6;
            @extend %flex-column-c-c;
        }
        &-name {
            @extend %flex-column-c-c;
        }
    }
    .versus {
        width: 2rem;
        height: 2rem;
        align-self: flex-start;
        @extend %m-t-two-space;
        @extend %ratio-1-1;
        @extend %flex-c-c;
        @extend %font-16-pb;
    }
    .icon {
        width: 3.4rem;
        aspect-ratio: .89;
        background: url(/static-assets/images/svg/gold-medal.svg?v=#{$image-version}) center / contain no-repeat;
        @extend %m-x-full;
    }
    .meta {
        @extend %font-18-pr;
        @extend %c-white-6;
    }
    .style {
        @extend %flex;
        &:after {
            content: '|';
        }
    }
    .won {
        .team-result {
            @extend %c-orange-10;
        }
    }
    .fname {
        @extend %font-14-pb;
        @extend %c-white-8;
    }
    .lname {
        @extend %font-14-pb;
        @extend %c-white-10;
    }
    .swiper-button-prev,
    .swiper-button-next {
        @extend %d-none;
    }
    .swiper-pagination {
        --swiper-pagination-bottom: var(--two-space);
    }
}
@media (min-width:$tablet-min-width) {
    .event-poll {
        padding: 0;
        .card {
            &-info {
                max-width: 40rem;
                border-radius: .9rem;
                border-width: .1rem;
                margin-top: var(--two-space);
                box-shadow: 0 .4rem 2.5rem 0 hsl(var(--hsl-c-black)/ .15);
                backdrop-filter: blur(.45rem);
                position: unset;
            }
            &-thumbnail {
                bottom: 0;
                top: 0;
            }
            &-title {
                margin-top: 9.8rem;
            }
        }
        .style {
            margin-inline: var(--half-space);
        }
        .icon {
            width: 5.8rem;
        }
        .fname,
        .lname {
            font-size: 1.8rem;
        }
        .card-info .team-a {
            position: relative;
            &::before,
            &::after {
                content: "";
                width: .1rem;
                height: calc(50% - 1.5rem);
                background: hsl(var(--hsl-c-white) / .2);
                pointer-events: none;
            }
            &::before {
                @include position(0, -2rem);
            }
            &::after {
                @include position(null, -2rem, 0);
            }
        }
        .versus {
            margin-top: 0;
            position: relative;
            font-size: 2.2rem;
            align-self: unset;
            line-height: 1;
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: auto;
            display: block;
            &::after {
                color: var(--c-white);
                font-size: 4rem;
            }
        }
        .swiper-button-prev {
            left: var(--full-space);
        }
        .swiper-button-next {
            right: var(--full-space);
        }
    }
}
@media (min-width:$desktop-min-width) {
    .event-poll {
        margin-bottom: calc(2 * var(--two-space));
        .date {
            max-width: unset;
            width: unset;
        }
        .card {
            &-title {
                font-size: 6.4rem;
            }
        }
    }
    .watch-center {
        .event-poll {
            margin-bottom: 0;
        }
    }
}