@use "../config" as *;

.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		aspect-ratio: 1/1;
		@extend %flex-c-c;

		&.swiper-button-disabled {
			cursor: not-allowed;
		}

		&::after {
			font: 1.8rem/1 $font-icon;
			@extend %c-pure-black-10;
			@extend %c-black-10;
		}
	}
	&-button-prev {
		&::after {
			content: "\e800";
		}


	}
	&-button-next {
		&::after {
			content: "\e801";
		}
	}
	&-pagination-bullet {
		width: 1rem;
		height: .2rem;
		
		border-radius: var(--half-radius);
		background-color: hsl(var(--hsl-c-pure-white)/7);

		&-active-main {
			width: 2rem;
			height: .4rem;
			background-color: var(--c-orange);
		}
	}
	&-pagination-bullet {
        width: 2rem;
        height: 0.5rem;
        position: relative;
        overflow: hidden;
        @extend %rounded-radius;
        @extend %c-white-bg-4;
    }
	[pagination-type = "custom"]{
		position: relative;
		gap: var(--half-space);
		display: inline-flex;
		@extend %m-y-full;
		.swiper-pagination-bullet {
			width: 2rem;
			height: 4rem;
			background: transparent;
			opacity: 1;
			cursor: pointer;
			@extend %c-white-10;
			@extend %ratio-1-1;
			@extend %font-18-pr;
			&.swiper-pagination-bullet-active{
				@extend %c-orange-10;
			  }
		  }
		 
	}
	.swiper-pagination-custom-prev,.swiper-pagination-custom-next{
		display: inline-block;
		@extend %m-x-full;
		.swiper-pagination-arrow{
			@extend %flex-c-c;
			@extend %c-white-10;
			@extend %font-18-pr;
			@extend %m-y-full;

		}
	}
}